import React from 'react';
import { NavLink } from 'react-router-dom';
import Switcher from '../Elements/Switcher';

var bgimage = require('./../../images/background/bg-site.png');

class Footer2 extends React.Component {
    constructor(props) {
        super(props);
        this.currentstate = {logo: require('./../../images/logo-styled.png')};
    }
    
    updateFooterLogo = (updatedlogo) => {this.currentstate.logo = updatedlogo;}
    render() {
        return (
            <>
                <footer className="site-footer footer-large  footer-light	footer-wide">
                    {/* FOOTER BLOCKES START */}
                    <div className="footer-top overlay-wraper">
                        <div className="overlay-main" />
                        <div className="container">
                            <div className="row">
                                {/* ABOUT COMPANY */}
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    <div className="widget widget_about">
                                        {/*<h4 class="widget-title">About Company</h4>*/}
                                        <div className="logo-footer clearfix p-b15">
                                        <NavLink to={"./"}><img src={this.currentstate.logo} alt="" /></NavLink>
                                        </div>
                                        <p className="max-w400">Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                                        
                                    </div>
                                </div>
                                {/* CONTACT US */}
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    <div className="widget widget_address_outer">
                                        <h4 className="widget-title">Contact Us</h4>
                                        <ul className="widget_address">
                                            <li className='icon-footer'><i className="fa fa-phone" /><a href="tel:727-645-9058">{' '} (727) 645-9058</a></li>
                                            <li className='icon-footer'><i className="fa fa-envelope" /><a href="mailto:info@integrityconcretefl.com">{' '} info@integrityconcretefl.com</a></li>
                                            <li className='icon-footer'><i className="fa fa-location-dot" /><a href="">{' '} P.O. Box 2436, Land O' Lakes, FL 34638</a></li>                                                                                
                                        </ul>
                                    </div>
                                </div>
                                {/* USEFUL LINKS */}
                                <div className="col-lg-3 col-md-6 col-sm-6 footer-col-3">
                                    <div className="widget widget_services inline-links">
                                        <h4 className="widget-title">Useful links</h4>
                                        <ul>
                                            <li className='icon-footer'><NavLink to={"/about"}><i className="fa fa-arrow-right" /> About</NavLink></li>
                                            <li className='icon-footer'><NavLink to={"/services"}><i className="fa fa-arrow-right" /> Services</NavLink></li>
                                            <li className='icon-footer'><NavLink to={"/projects"}><i className="fa fa-arrow-right" /> Projects</NavLink></li>
                                            <li className='icon-footer'><NavLink to={"/contact"}> <i className="fa fa-arrow-right" /> Contact Us</NavLink></li>
                                        </ul>
                                    </div>
                                </div>
                                {/* SOCIAL MEDIA */}
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    <div className="widget recent-posts-entry-date">
                                        <h4 className="widget-title">Follow Us</h4>
                                        <div className="widget-post-bx">                                            
                                            <ul className="social-icons  mt-social-links">
                                                <li className='social-container'><NavLink to={"https://www.facebook.com/integrityconcretefl"} className="fa fa-facebook" /></li>
                                                <li className='social-container'><NavLink to={"https://www.linkedin.com/company/integrityconcretefl"} className="fa fa-linkedin" /></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {/* NEWSLETTER */}
                            </div>
                        </div>
                    </div>
                    {/* FOOTER COPYRIGHT */}
                    <div className="footer-bottom overlay-wraper">
                        <div className="overlay-main" />
                        <div className="container">
                            <div className="row">
                                <div className="mt-footer-bot-center">
                                    <span className="copyrights-text">© Copyright 2023. <a href="/">Integrity Mobile Concrete</a>. All Rights Reserved.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                <Switcher updateFooterLogo={this.updateFooterLogo.bind(this)}/>

            </>
        );
    };
};

export default Footer2;