import React from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const images = [
    require('./../../images/gallery/portrait/pic4.jpg'),
    require('./../../images/gallery/portrait/pic5.jpg'),
    require('./../../images/gallery/portrait/pic6.jpg'),
    require('./../../images/gallery/portrait/pic7.jpg'),
    require('./../../images/gallery/portrait/pic1.jpg'),
]

var bnr1 = require('./../../images/background/line.png');

class About4 extends React.Component {
    
    componentDidMount(){
        function loadScript(src) {
           
          return new Promise(function(resolve, reject){
            var script = document.createElement('script');
            script.src = src;
            script.addEventListener('load', function () {
              resolve();
            });
            script.addEventListener('error', function (e) {
              reject(e);
            });
            document.body.appendChild(script);
            document.body.removeChild(script);
          })
        };
 
      loadScript('./assets/js/masonary.js');
      
    };
    
    render() {
        const options = {
            loop:true,
            autoplay:true,
            autoplayTimeout:3000,
            margin:30,
            nav:false,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            items:1,
            dots: true
        };
        return (
            <>
                <div className="section-full p-t80 p-b80 bg-white inner-page-padding">
                    <div className="container">
                        <div className="section-content ">
                            <div className="row">
                                <div className="col-lg-5 col-md-5 ">
                                    <div className="m-about ">
                                        <OwlCarousel className="owl-carousel about-us-carousel" {...options}>
                                            {images.map((item, index) => (
                                                <div className="item" key={index}>
                                                    <div className="ow-img">
                                                    <NavLink to="/about"><img src={item} alt=""/></NavLink>
                                                    </div>
                                                </div>
                                            ))}
                                        
                                        </OwlCarousel>
                                        
                                    </div>
                                </div>
                                <div className="col-lg-7 col-md-7">
                                    <div className="m-about-containt text-black p-t80">
                                        <div className="m-about-years bg-moving" style={{backgroundImage:"url(" + bnr1 + ")"}}>
                                            <span className="text-primary large-title"></span>
                                            <span className="large-title-info">BUILDING ON INTEGRITY, ONE POUR AT A TIME.</span>
                                        </div>
                                        <h3 className="font-weight-600">Improving quality of life with an integrated unified approach.</h3>
                                        <p>IMC is a leading mobile concrete company dedicated to delivering high-quality concrete solutions to clients throughout the region. With years of experience and a commitment to excellence, we have established ourselves as one of the most trusted and reliable concrete providers in the area. Our team of experts works closely with each client to understand their unique needs and provide customized solutions that meet their specific requirements. From residential concrete foundations to large-scale commercial projects, we have the skills and equipment to get the job done right. At IMC, we are passionate about providing outstanding customer service and delivering results that exceed expectations. Learn more about our team and our commitment to excellence by contacting us today.</p>

                                            <NavLink to="/contact" className="site-button btn-effect m-b15"><span>Get in touch</span></NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default About4;